.label {
  padding: 2px 6px;
  line-height: 30px;
}

.label.dead {
  background: #f1565f;
}

.label.slaughter {
  background: #ecd06e;
}

.label.alive {
  background: #4dc5b6;
}
