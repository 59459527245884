@import url('https://fonts.googleapis.com/css?family=Finger+Paint|Lato|Patrick+Hand+SC|Knewave|Trade+Winds');

html, body {
  margin: 0;
  padding: 0;
  background-color: #cd5b5c;
  font-family: 'Lato', sans-serif;
}


* {
  box-sizing: border-box;
}

::selection {
  background: #ecd06e;
}

.handwritten {
  font-family: 'Patrick Hand SC', sans-serif;
}

.hidden {
  visibility: hidden;
}

.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
