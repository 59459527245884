.section-title {
  position: relative;
  font-size: 35px;
  font-weight: normal;
}

@media (min-width: 894px) {
  .section-title:before {
    content: '';
    position: absolute;
    top: 51%;
    width: 100%;
    height: 1px;
    background-color: white;
    left: 0;
  }
}
.section-title > div {
  background: #cd5b5c;
  position: relative;
  padding: 10px 40px;
  margin: 20px auto;
  width: 75vw;
  min-width: 300px;
  max-width: 370px;
}

.section-title.secondary > div {
  background: #43565f;
}
