.app {
  text-align: center;
  color: white;
}

.app-header {
  background-color: #222;
  padding: 20px 20px 30px;
  color: white;
}

.app-title {
  font-family: 'Knewave', 'Finger Paint', sans-serif;
  font-weight: normal;
  font-size: 37px;
  color: #ecd06e;
  margin: 5px;
}

@media (min-width: 542px) {
  .app-title {
    font-size: 50px;
  }
}


.app-description {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}
