.animal {
  width: 75vw;
  min-width: 300px;
  max-width: 370px;
  padding: 8px;
  margin: 16px;
  background: #fff;
  color: black;
  display: flex;
}

.animal .picture {
  display: flex;
  min-width: 75px;
}

.animal .picture img {
  margin: auto
}

.animal .content {
  flex: 1;
  padding: 0 .5rem;
  margin: auto;
}

.animal .name {
  text-align: left;
  color: #43565f;
  margin-bottom: 5px;
  font-size: 20px;
}

.animal .progress-bar {
  background: #cd5b5c;
  color: white;
  display: flex;
  height: 30px;
  border: 2px solid #222;
}
.animal.alive .progress-bar {
  background: #4dc5b6;
}

.animal .progress-bar .progress {
  background: #ecd06e;
}

.animal .progress-bar .lifespan,
.animal .progress-bar .slaughter {
  margin: auto 5px;
}

.animal .progress-bar .lifespan {
  margin-left: auto;
}
