.section {
  background-color: #43565f;
  padding: 20px 0;
}

.section.secondary {
  background-color: #cd5b5c;
}

.section > div {
  width: 90vw;
  max-width: 371px;
  margin: 0 auto;
}

@media (min-width: 894px) {
  .section > div {
    max-width: 774px;
  }
}

@media (min-width: 1340px) {
  .section > div {
    max-width: 1174px;
  }
}

.section.full-width > div {
  max-width: unset;
}
