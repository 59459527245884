@import url(https://fonts.googleapis.com/css?family=Finger+Paint|Lato|Patrick+Hand+SC|Knewave|Trade+Winds);
html, body {
  margin: 0;
  padding: 0;
  background-color: #cd5b5c;
  font-family: 'Lato', sans-serif;
}


* {
  box-sizing: border-box;
}

::-moz-selection {
  background: #ecd06e;
}

::selection {
  background: #ecd06e;
}

.handwritten {
  font-family: 'Patrick Hand SC', sans-serif;
}

.hidden {
  visibility: hidden;
}

.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

.app {
  text-align: center;
  color: white;
}

.app-header {
  background-color: #222;
  padding: 20px 20px 30px;
  color: white;
}

.app-title {
  font-family: 'Knewave', 'Finger Paint', sans-serif;
  font-weight: normal;
  font-size: 37px;
  color: #ecd06e;
  margin: 5px;
}

@media (min-width: 542px) {
  .app-title {
    font-size: 50px;
  }
}


.app-description {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.input-age {
  background: transparent;
  border: 0;
  border-bottom: 1px solid white;
  color: white;
  text-align: center;
  width: 125px;
  line-height: 50px;
  font-size: 50px;
  -moz-appearance: textfield;
}

.input-age:focus {
  outline: none;
  border-bottom-color: #4dc5b6;
}

.input-age::-webkit-outer-spin-button,
.input-age::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.section-title {
  position: relative;
  font-size: 35px;
  font-weight: normal;
}

@media (min-width: 894px) {
  .section-title:before {
    content: '';
    position: absolute;
    top: 51%;
    width: 100%;
    height: 1px;
    background-color: white;
    left: 0;
  }
}
.section-title > div {
  background: #cd5b5c;
  position: relative;
  padding: 10px 40px;
  margin: 20px auto;
  width: 75vw;
  min-width: 300px;
  max-width: 370px;
}

.section-title.secondary > div {
  background: #43565f;
}

.section {
  background-color: #43565f;
  padding: 20px 0;
}

.section.secondary {
  background-color: #cd5b5c;
}

.section > div {
  width: 90vw;
  max-width: 371px;
  margin: 0 auto;
}

@media (min-width: 894px) {
  .section > div {
    max-width: 774px;
  }
}

@media (min-width: 1340px) {
  .section > div {
    max-width: 1174px;
  }
}

.section.full-width > div {
  max-width: unset;
}

.age {
  background-color: #43565f;
}

.age-question {
  font-size: 60px;
}

.animals {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;
}

.animal {
  width: 75vw;
  min-width: 300px;
  max-width: 370px;
  padding: 8px;
  margin: 16px;
  background: #fff;
  color: black;
  display: flex;
}

.animal .picture {
  display: flex;
  min-width: 75px;
}

.animal .picture img {
  margin: auto
}

.animal .content {
  flex: 1 1;
  padding: 0 .5rem;
  margin: auto;
}

.animal .name {
  text-align: left;
  color: #43565f;
  margin-bottom: 5px;
  font-size: 20px;
}

.animal .progress-bar {
  background: #cd5b5c;
  color: white;
  display: flex;
  height: 30px;
  border: 2px solid #222;
}
.animal.alive .progress-bar {
  background: #4dc5b6;
}

.animal .progress-bar .progress {
  background: #ecd06e;
}

.animal .progress-bar .lifespan,
.animal .progress-bar .slaughter {
  margin: auto 5px;
}

.animal .progress-bar .lifespan {
  margin-left: auto;
}

.result-share {
  background-color: #43565f;
}

.fb_iframe_widget {
  top: -3px;
  margin-right: 9px;
}

.label {
  padding: 2px 6px;
  line-height: 30px;
}

.label.dead {
  background: #f1565f;
}

.label.slaughter {
  background: #ecd06e;
}

.label.alive {
  background: #4dc5b6;
}

.result-explanation .animal {
  margin: 20px auto 35px;
}

.result-explanation .animal .progress-bar {
  font-size: 80%;
}

@media (min-width: 460px) {
  .result-explanation .animal .progress-bar {
    font-size: inherit;
  }
}

.result-explanation .explanation-bar-list {
  padding-left: 16px;
}

