.result-explanation .animal {
  margin: 20px auto 35px;
}

.result-explanation .animal .progress-bar {
  font-size: 80%;
}

@media (min-width: 460px) {
  .result-explanation .animal .progress-bar {
    font-size: inherit;
  }
}

.result-explanation .explanation-bar-list {
  padding-left: 16px;
}
