.input-age {
  background: transparent;
  border: 0;
  border-bottom: 1px solid white;
  color: white;
  text-align: center;
  width: 125px;
  line-height: 50px;
  font-size: 50px;
  -moz-appearance: textfield;
}

.input-age:focus {
  outline: none;
  border-bottom-color: #4dc5b6;
}

.input-age::-webkit-outer-spin-button,
.input-age::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
